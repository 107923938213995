<template>
  <div class="h-screen">
    <!-- Redirection -->
    <div class="flex-col-center justify-center h-full">
      <Loader :onlyLoader="false" title="" :text="$t('redirecting')" />
    </div>
  </div>
</template>

<script>
// Framework components
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"

// App components
import Loader from "@/components/UI/Loader"

const sprintf = require("sprintf-js").sprintf

export default {
  name: "Redirect",

  components: {
    // App components
    Loader
  },

  data() {
    return {
      token: this.$route.query?.access_token || localStorage.getItem("Token"),
      hasInvitations: this.$route.query?.hasInvitations,
      user: {},
      formUserValues: new FormClient({ include: "projects" })
    }
  },

  mounted() {
    if (!this.token || this.token == "null") {
      this.$router.push({ name: "Login" })
    }

    this.loginAndRedirect(this.token)
  },

  methods: {
    async loginAndRedirect(token) {
      await this.$store.dispatch("Auth/setToken", token)
      const response = await this.formUserValues.get(ApiRoute.auth.profile)
      await this.$store.dispatch("Auth/setUser", response.data)
      this.user = response.data.data

      // If the user had an invitation, redirect to invitations.
      if (!this.user.is_email_verified) {
        this.$router.push({ name: "Verification user" })
        return
      }

      const responseInvitation = await (new FormClient()).get(sprintf(ApiRoute.user.invitations, { user: this.user.id }))
      let invitationsPending = responseInvitation.data.data
        .filter((invitation) => invitation.is_pending)

      // If the user had an invitation, redirect to invitations.
      if (invitationsPending.length && !(this.$store.state.Auth.user?.config?.project_default_id || this.$store.state.Auth.user?.start_project_id)) {
        this.$router.push({ name: "Invitations" })
        return
      }

      // If the user had missing basic information, redirect to onboarding.
      if (!this.user.company_rol_id || !this.user.config?.language) {
        this.$router.push({ name: "Onboarding user" })
        return
      }

      // If the user had a project assigned and if the project is premium or not.
      if (this.$store.state.Auth.user?.config?.project_default_id || this.$store.state.Auth.user?.start_project_id) {
        let project = this.user.projects.find((project) => project.id == this.$store.state.Auth.user?.config?.project_default_id)
        if (!project) {
          project = this.user.projects.find((project) => project.id == this.$store.state.Auth.user?.start_project_id)
        }

        if (!project && this.user.projects.length) {
          this.$router.push({ name: "Projects", params: { id: "" } })
          return
        }

        if (project && project.is_premium) {
          await this.$store.dispatch("Projects/getProject", project.id)
          this.$router.push({ name: "Dashboard", params: { id: project.id } })
          return
        } else {
          this.$router.push({ name: "Integrations", params: { id: project.id } })
          return
        }
      }

      this.$router.push({ name: "Onboarding project" })
    }
  }
}
</script>
